import { useState, useEffect, useRef, useCallback } from 'react';
import { on, emit, removeAllListeners } from '../../socket';
import { Device } from 'mediasoup-client';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, message, Tooltip } from 'antd';
import {
  AudioOutlined,
  PhoneOutlined,
  DesktopOutlined,
  SettingOutlined,
  VideoCameraOutlined,
  MoreOutlined,
  RetweetOutlined,
  AudioMutedOutlined,
} from '@ant-design/icons';
import ACTIONS from '../../utils/socketActions';
import styles from './GroupCallsPage.module.css';
import { UsersListDrawer } from './UsersListDrawer';
import { isMobile } from 'react-device-detect';
import noCamera from '../../images/no-camera.png';

export default function GroupCallsPage() {
  const history = useNavigate();
  const { id: roomName, fullRoomName, wantsEnableAudio, wantsEnableVideo, selectedBitrate: bitrate } = useParams();
  const localVideo = useRef(null);
  const localMediaStream = useRef(null);

  // массив подключений (изменяется беком)
  const [allUsers, setAllUsers] = useState([localVideo]);

  // массив разрешенных для отображения подключений (изменяется фронтом)
  const [visibleUsers, setVisibleUsers] = useState([localVideo]);

  // массив ОТОБРАЖАЕМЫХ подключений (для перебора с фильтрацией по разрешенным)
  const [usersToShow, setUsersToShow] = useState([localVideo]);

  const device = useRef(null);
  const consumerTransports = useRef([]);
  const consumingTransports = useRef([]);
  const [screenShare, setScreenShare] = useState(false);
  const [cameraOn, setCameraOn] = useState(wantsEnableVideo === 'true' ? true : false);
  const [audioOn, setAudioOn] = useState(wantsEnableAudio === 'true' ? true : false);
  const [fullScreenVideo, setFullScreenVideo] = useState(null);
  const [mutedUsers, setMutedUsers] = useState([]);
  const [canUseAudio, setCanUseAudio] = useState(true);
  const [canUseVideo, setCanUseVideo] = useState(true);
  const [selectedBitrate, setSelectedBitrate] = useState(bitrate === 'max' ? 2 : bitrate === 'mid' ? 1 : 0);

  let credentials;

  useEffect(() => {
    getLocalStream();
    on('new-producer', ({ producerId, id, label, kind, enable }) => {
      if (producerId.split(':')[0] === 'fake') {
        const newElem = { producerId: `td-${producerId}`, kind, id, label, enable: false };
        setAllUsers((prev) => {
          return [...prev, newElem];
        });
      } else {
        signalNewConsumerTransport(producerId, id, label, enable);
      }
    });

    on('producer-closed', producerClosedHandler);
    return () => {
      if (localMediaStream.current) {
        localMediaStream.current.getTracks().forEach((track) => track.stop());
      }
      cleanupTransports();
      removeAllListeners('pauseaudio');
      removeAllListeners('pausevideo');
      emit('pageUnmount', null);
    };
  }, []);

  useEffect(() => {
    let missingUsers = [];
    if (allUsers.length > 0) {
      allUsers.map((peer) => {
        if (peer?.kind === 'video' && !allUsers.find((user) => peer?.id === user.id && user.kind === 'audio')) {
          missingUsers.push({ kind: 'audio', id: peer?.id, enable: false, producerId: '', label: peer?.label });
        }
        if (peer?.kind === 'audio' && !allUsers.find((user) => peer?.id === user.id && user.kind === 'video')) {
          missingUsers.push({ kind: 'video', id: peer?.id, enable: false, producerId: '', label: peer?.label });
        }
      });
    }

    if (!allUsers.find((user) => user?.producerId === fullScreenVideo)) {
      setFullScreenVideo(null);
    }

    setUsersToShow([...allUsers, ...missingUsers]);

    if (fullScreenVideo) {
      setVisibleUsers([
        ...allUsers.filter((peer) => (fullScreenVideo ? peer?.producerId === fullScreenVideo : true)),
        ...missingUsers.filter((peer) => (fullScreenVideo ? peer?.producerId === fullScreenVideo : true)),
      ]);
    } else {
      setVisibleUsers([...allUsers, ...missingUsers]);
    }
    on('pauseaudio', ({ producerId }) => {
      changeEnableKindByProducerId(producerId, 'audio');
    });
    on('pausevideo', ({ producerId }) => {
      changeEnableKindByProducerId(producerId, 'video');
    });
    on('turnOnScreenShare', ({ id }) => {
      turnOnScreenShare(id);
    });
    on('turnOffScreenShare', ({ id }) => {
      turnOffScreenShare(id);
    });
    return () => {
      removeAllListeners('pauseaudio');
      removeAllListeners('pausevideo');
      removeAllListeners('turnOnScreenShare');
      removeAllListeners('turnOffScreenShare');
    };
  }, [allUsers, fullScreenVideo, mutedUsers]);

  const settingsItems = [
    {
      key: 'selectBitrate',
      label: 'Изменить битрейт',
      children: [
        {
          key: 'selectBitrate_0',
          label: 'Низкий (100 kbps)',
          disabled: selectedBitrate === 0,
        },
        {
          key: 'selectBitrate_1',
          label: 'Средний (300 kbps)',
          disabled: selectedBitrate === 1,
        },
        {
          key: 'selectBitrate_2',
          label: 'Высокий (900 kbps)',
          disabled: selectedBitrate === 2,
        },
      ],
    },
  ];

  const changeEnableKindByProducerId = (producerId, kind) => {
    const finder = allUsers.find((user) => user?.producerId === `td-${producerId}` && user.kind === kind);
    if (finder) {
      setAllUsers((prev) =>
        prev.map((user) => {
          if (user !== finder) return user;
          finder.enable = !finder.enable;
          return finder;
        })
      );
    }
  };

  const turnOnScreenShare = (id) => {
    let finder;
    allUsers.map((user) => (user.id === id && user.kind === 'video' && !user.enable ? (finder = user) : null));
    if (finder) {
      setAllUsers(allUsers.filter((user) => user !== finder));
    }
  };

  const turnOffScreenShare = (id) => {
    setAllUsers(
      allUsers.map((user) => {
        if (user.id === id && user.kind === 'video') {
          return { ...user, enable: false };
        } else {
          return user;
        }
      })
    );
  };

  let rtpCapabilities;
  const producerTransport = useRef({});
  let audioProducer;
  const videoProducer = useRef();

  let audioParams;
  let videoParams = {
    encodings: [
      { rid: 'r0', maxBitrate: 100000, scalabilityMode: 'S1T3' },
      { rid: 'r1', maxBitrate: 300000, scalabilityMode: 'S1T3' },
      { rid: 'r2', maxBitrate: 900000, scalabilityMode: 'S1T3' },
    ],
    codecOptions: { videoGoogleStartBitrate: 1000 },
  };

  const streamSuccess = async (stream) => {
    if (stream) {
      if (localVideo.current) {
        localVideo.current.srcObject = stream;
      }

      audioParams = { track: stream.getAudioTracks()[0], ...audioParams };
      videoParams = { track: stream.getVideoTracks()[0], ...videoParams };
    }
    await joinRoom();
  };

  useEffect(() => {
    if (videoProducer.current) videoProducer.current.setMaxSpatialLayer(selectedBitrate);
  }, [selectedBitrate]);

  const joinRoom = async () => {
    emit('joinRoom', { roomName, fullRoomName, video: canUseVideo, audio: canUseAudio }, async (data) => {
      rtpCapabilities = data.rtpCapabilities;
      await createDevice(rtpCapabilities);
    });
  };

  const getLocalStream = useCallback(async () => {
    let letUseVideo = true,
      letUseAudio = true;
    try {
      localMediaStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { width: { min: 640, max: 1920 }, height: { min: 400, max: 1080 } },
      });
      await streamSuccess(localMediaStream.current);
    } catch (error) {
      try {
        localMediaStream.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        await streamSuccess(localMediaStream.current);
        setCameraOn(false);
        setAudioOn(true);
      } catch (audioError) {
        setAudioOn(false);
        letUseAudio = false;
        setCanUseAudio(false);
        message.error('Нет доступа к микрофону');
        console.error('Ошибка доступа к аудио:', audioError.message);
      }

      try {
        localMediaStream.current = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true,
        });
        await streamSuccess(localMediaStream.current);
        setCameraOn(true);
        setAudioOn(false);
      } catch (videoError) {
        setCameraOn(false);
        letUseVideo = false;
        setCanUseVideo(false);
        message.error('Нет доступа к камере');
        console.error('Ошибка доступа к аудио:', videoError.message);
      }

      if (!letUseAudio && !letUseVideo) {
        streamSuccess();
      }
    }
  }, []);

  const createDevice = async (rtpCapabilities) => {
    try {
      device.current = new Device();

      await device.current.load({ routerRtpCapabilities: rtpCapabilities });
      console.log('Device RTP Capabilities', device.current.rtpCapabilities);
      await createSendTransport();
    } catch (error) {
      console.log(error);
      if (error.name === 'UnsupportedError') {
        console.warn('browser not supported');
      }
    }
  };

  const createSendTransport = async () => {
    emit('createWebRtcTransport', { consumer: false }, async ({ params }) => {
      if (params.error) {
        console.log(params.error);
        return;
      }
      if (!credentials) {
        credentials = await fetchCredentials();
      }
      producerTransport.current = device.current.createSendTransport({
        iceServers: [
          {
            urls: 'turn:turn.stormapi.su:3479',
            username: credentials.username,
            credential: credentials.password,
          },
        ],
        ...params,
      });

      producerTransport.current.on('connect', async ({ dtlsParameters }, callback, errback) => {
        try {
          emit('transport-connect', { dtlsParameters });
          callback();
        } catch (error) {
          errback(error);
        }
      });

      producerTransport.current.on('produce', async (parameters, callback, errback) => {
        try {
          emit(
            'transport-produce',
            {
              kind: parameters.kind,
              rtpParameters: parameters.rtpParameters,
              isEnabled:
                (parameters.kind === 'video' && (cameraOn || parameters.appData.screenShare)) ||
                (parameters.kind === 'audio' && audioOn),
              appData: parameters.appData,
            },
            ({ id, producersExist }) => {
              callback({ id });
              if (producersExist) {
                getProducers();
              }
            }
          );
        } catch (error) {
          errback(error);
        }
      });

      await connectSendTransport(producerTransport);
    });
  };

  const fetchCredentials = async () => {
    const response = await fetch('/api/turnInfo/getCredentials', {
      method: 'GET',
      headers: {
        apikey: process.env.REACT_APP_TURN_SECRET,
      },
    });
    return await response.json();
  };

  const connectSendTransport = async (producerTransport) => {
    if (audioParams?.track) {
      audioProducer = await producerTransport.current.produce(audioParams);
    } else {
      try {
        emit(
          'transport-produce',
          {
            kind: 'audio',
            rtpParameters: null,
          },
          ({ producersExist }) => {
            if (producersExist) {
              getProducers();
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
    if (videoParams?.track) {
      videoProducer.current = await producerTransport.current.produce(videoParams);
      videoProducer.current.setMaxSpatialLayer(selectedBitrate);
    } else {
      try {
        emit(
          'transport-produce',
          {
            kind: 'video',
            rtpParameters: null,
          },
          ({ producersExist }) => {
            if (producersExist) {
              getProducers();
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    }

    if (audioProducer) {
      audioProducer.on('trackended', () => console.log('audio track ended'));
      audioProducer.on('transportclose', () => console.log('audio transport closed'));
    }

    if (videoProducer.current) {
      videoProducer.current.on('trackended', () => console.log('video track ended'));
      videoProducer.current.on('transportclose', () => console.log('video transport closed'));
    }
  };

  const signalNewConsumerTransport = async (remoteProducerId, id, label, enable) => {
    if (consumingTransports.current.includes(remoteProducerId)) return;
    consumingTransports.current.push(remoteProducerId);

    emit('createWebRtcTransport', { consumer: true }, async ({ params }) => {
      if (params.error) {
        console.log(params.error);
        return;
      }

      let consumerTransport;
      try {
        if (!credentials) {
          credentials = await fetchCredentials();
        }
        consumerTransport = device.current.createRecvTransport({
          iceServers: [
            {
              urls: 'turn:turn.stormapi.su:3479',
              username: credentials.username,
              credential: credentials.password,
            },
          ],
          ...params,
        });
      } catch (error) {
        console.log(error);
        return;
      }

      consumerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
        console.log(`connected consumer Transport`);
        try {
          emit('transport-recv-connect', { dtlsParameters, serverConsumerTransportId: params.id });
          callback();
        } catch (error) {
          errback(error);
        }
      });

      await connectRecvTransport(consumerTransport, remoteProducerId, params.id, id, label, enable);
    });
  };

  const getProducers = () => {
    emit('getProducers', null, (producerIds) => {
      producerIds.forEach(({ producerId, id, label, kind, enable }) => {
        if (producerId.split(':')[0] === 'fake') {
          const newElem = { producerId: `td-${producerId}`, kind, id, label, enable: false };
          setAllUsers((prev) => {
            return [...prev, newElem];
          });
        } else {
          signalNewConsumerTransport(producerId, id, label, enable);
        }
      });
    });
  };

  const connectRecvTransport = async (
    consumerTransport,
    remoteProducerId,
    serverConsumerTransportId,
    id,
    label,
    enable
  ) => {
    emit(
      'consume',
      {
        rtpCapabilities: device.current.rtpCapabilities,
        remoteProducerId,
        serverConsumerTransportId,
      },
      async ({ params }) => {
        if (params.error) {
          console.log('Cannot Consume');
          return;
        }

        const consumer = await consumerTransport.consume({
          id: params.id,
          producerId: params.producerId,
          kind: params.kind,
          rtpParameters: params.rtpParameters,
        });

        // Убедитесь, что consumer корректно создается
        if (consumer) {
          consumerTransports.current = [
            ...consumerTransports.current,
            { consumerTransport, serverConsumerTransportId: params.id, producerId: remoteProducerId, consumer },
          ];

          // Добавляем нового пользователя в список
          const newElem = { producerId: `td-${remoteProducerId}`, kind: params.kind, id, label, enable };
          setAllUsers((prev) => {
            return [
              ...prev.filter((peer, index, array) =>
                array.slice(index + 1).find((user) => user.id === peer.id && user.kind === peer.kind) ? false : true
              ),
              newElem,
            ];
          });

          // Запускаем поток
          emit('consumer-resume', { serverConsumerId: params.serverConsumerId });
        }
      }
    );
  };

  const cleanupTransports = () => {
    if (Object.keys(producerTransport.current).length !== 0) {
      producerTransport.current.close();
    }
    if (audioProducer) {
      audioProducer.close();
    }
    if (videoProducer.current) {
      videoProducer.current.close();
    }
    consumerTransports.current.forEach(({ consumerTransport, consumer }) => {
      consumerTransport.close();
      consumer.close();
    });

    consumerTransports.current = [];
  };

  const producerClosedHandler = ({ remoteProducerId }) => {
    const producerToClose = consumerTransports.current.find(
      (transportData) => transportData.producerId === remoteProducerId
    );
    if (producerToClose) {
      producerToClose.consumerTransport.close();
      producerToClose.consumer.close();
    }
    consumerTransports.current = consumerTransports.current.filter(
      (transportData) => transportData.producerId !== remoteProducerId
    );
    setAllUsers((prev) => {
      return prev.filter((user) => user.producerId !== `td-${remoteProducerId}`);
    });
  };

  const provideMediaRef = useCallback((userId, videoElement) => {
    if (!videoElement) return;
    const transportData = consumerTransports.current.find(
      (transportData) => `td-${transportData.producerId}` === userId
    );

    if (transportData && transportData.consumer) {
      if (transportData?.consumer?._track && !transportData?.consumer?._track.muted) {
        videoElement.srcObject = new MediaStream([transportData.consumer._track]);
      }
    } else {
      console.log(`No media found for user: ${userId}`);
    }
  }, []);

  // Обработчики нажатия на кнопки
  const handleVideoButtonClick = (e, id) => {
    if (e.key === 'mute') {
      setMutedUsers((prev) => {
        return [...prev, id];
      });
    }
    if (e.key === 'unmute') {
      setMutedUsers((prev) => {
        return [...prev].filter((mutedId) => mutedId !== id);
      });
    }
  };

  const handleCameraClick = async () => {
    if (canUseVideo) {
      const videoTrack = localMediaStream.current.getVideoTracks()[0];

      if (screenShare) {
        const screenTrack = localMediaStream.current.getVideoTracks().find((track) => track.kind === 'video');
        screenTrack.stop();

        const newStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        const newVideoTrack = newStream.getVideoTracks()[0];
        await switchVideo(screenTrack, newVideoTrack);

        setScreenShare(false);
        setCameraOn(true);
      } else if (videoTrack) {
        emit('toggleVideo');
        videoTrack.enabled = !cameraOn;
        setCameraOn(!cameraOn);
      }
    } else {
      message.error('Нет доступа к камере');
    }
  };

  const handleAudioClick = () => {
    if (canUseAudio) {
      emit('toggleAudio');
      setAudioOn(!audioOn);
    } else {
      message.error('Нет доступа к микрофону');
    }
  };

  const handleStopCallClick = () => {
    if (localMediaStream.current) {
      localMediaStream.current.getTracks().forEach((track) => track.stop());
    }
    cleanupTransports();
    emit(ACTIONS.LEAVE);
    history('/call_page');
  };

  const switchVideo = async (videoTrack, newVideoTrack) => {
    videoTrack.stop();
    for (const producer of producerTransport.current._producers.values()) {
      if (producer.kind === 'video') {
        await producer.replaceTrack({ track: newVideoTrack });
        break;
      }
    }
    localMediaStream.current.removeTrack(videoTrack);
    localMediaStream.current.addTrack(newVideoTrack);

    if (localVideo.current) {
      localVideo.current.srcObject = localMediaStream.current;
    }
  };

  const handleScreenClick = async () => {
    try {
      if (!screenShare) {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: false,
        });

        const videoTrack = localMediaStream?.current?.getVideoTracks()[0];
        const screenVideoTrack = screenStream.getVideoTracks()[0];

        if (videoTrack) videoTrack.stop();
        for (const producer of producerTransport.current._producers.values()) {
          if (producer.kind === 'video') {
            await producer.close();
            break;
          }
        }
        if (videoTrack) localMediaStream.current.removeTrack(videoTrack);
        if (localMediaStream.current) {
          localMediaStream.current.addTrack(screenVideoTrack);
        } else {
          localMediaStream.current = screenStream;
        }
        videoParams = { track: screenVideoTrack, ...videoParams };
        const newProducer = await producerTransport.current.produce({ ...videoParams, appData: { screenShare: true } });

        newProducer.on('trackended', () => {
          console.log('New video track ended');
          screenVideoTrack.stop();
        });

        newProducer.on('transportclose', () => {
          console.log('Transport for new video track closed');
          screenVideoTrack.stop();
        });

        if (localVideo.current) {
          localVideo.current.srcObject = localMediaStream.current;
        }

        setScreenShare(true);
        setCameraOn(false);
        emit('turnOnScreenShare', roomName);
        message.info('Демонстрация экрана включена');

        screenVideoTrack.onended = async () => {
          const screenTrack = localMediaStream.current.getVideoTracks().find((track) => track.kind === 'video');
          screenTrack.stop();
          if (!canUseVideo) emit('turnOffScreenShare', roomName);
          message.info('Демонстрация экрана завершена');

          if (canUseVideo) {
            const newStream = await navigator.mediaDevices.getUserMedia({
              video: true,
            });
            const newVideoTrack = newStream.getVideoTracks()[0];
            await switchVideo(screenTrack, newVideoTrack);
            setScreenShare(false);
            setCameraOn(true);
          } else {
            setScreenShare(false);
            setCameraOn(false);
          }
        };
      } else {
        const screenTrack = localMediaStream.current.getVideoTracks().find((track) => track.kind === 'video');
        screenTrack.stop();
        if (!canUseVideo) emit('turnOffScreenShare', roomName);
        message.info('Демонстрация экрана завершена');

        if (canUseVideo) {
          const newStream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          const newVideoTrack = newStream.getVideoTracks()[0];
          await switchVideo(screenTrack, newVideoTrack);
          setScreenShare(false);
          setCameraOn(true);
        } else {
          setScreenShare(false);
          setCameraOn(false);
        }
      }
    } catch (error) {
      console.error('Ошибка захвата экрана:', error);
    }
  };

  const toggleFacingMode = async () => {
    if (!localMediaStream.current) return;

    const videoTrack = localMediaStream.current.getVideoTracks()[0];
    const currentConstraints = videoTrack.getConstraints();

    try {
      videoTrack.stop();
      const newFacingMode = currentConstraints.facingMode === 'user' ? 'environment' : 'user';
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: newFacingMode,
        },
      });
      const newVideoTrack = newStream.getVideoTracks()[0];

      for (const producer of producerTransport.current._producers.values()) {
        if (producer.kind === 'video') {
          await producer.replaceTrack({ track: newVideoTrack });
          break;
        }
      }

      localMediaStream.current.removeTrack(videoTrack);
      localMediaStream.current.addTrack(newVideoTrack);

      if (localVideo.current) {
        localVideo.current.srcObject = localMediaStream.current;
      }
    } catch (error) {
      message.error('Ошибка переключения камеры: ' + error.message);
      console.error('Ошибка переключения камеры:', error);
    }
  };

  const handleSettingsClick = (e) => {
    switch (e.key) {
      case 'selectBitrate_0':
        handleChangeBitrate(0);
        console.log('selectBitrate_0');
        break;
      case 'selectBitrate_1':
        handleChangeBitrate(1);
        break;
      case 'selectBitrate_2':
        handleChangeBitrate(2);
        break;
    }
  };

  const handleChangeBitrate = (bitrate) => {
    setSelectedBitrate(bitrate);
  };

  return (
    <div className={styles.container}>
      <h1
        style={{ margin: '20px 0 20px', textAlign: 'center', fontSize: '24px', fontWeight: 'bold', userSelect: 'text' }}
      >
        Комната: {fullRoomName}
      </h1>
      <UsersListDrawer users={usersToShow} />
      <div
        className={`${
          visibleUsers.length === 1 || fullScreenVideo
            ? styles.oneVideo
            : visibleUsers.length === 3 || visibleUsers.length === 2 || visibleUsers.length === 4
            ? styles.twoVideos
            : styles.videoGrid
        }`}
      >
        {usersToShow
          .filter((peer) => (peer.kind === 'video' ? true : !mutedUsers.includes(peer.id)))
          .filter((peer, index, array) =>
            array.slice(index + 1).find((user) => user.id === peer.id && user.kind === peer.kind) ? false : true
          )
          .map((peer, index, array) => {
            if (peer != localVideo) {
              if (
                array
                  .map((user) => {
                    return `${Object.values(user)}`;
                  })
                  .indexOf(`${Object.values(peer)}`) !== index
              ) {
                return;
              }
            }
            return (
              <div
                key={index + 1}
                className={styles.videoItem}
                id={peer.producerId}
                style={{
                  display: peer.kind === 'audio' || !visibleUsers.includes(peer) ? 'none' : 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: peer === localVideo ? '3px solid rgb(152, 202, 255)' : '3px solid #c6c6c6',
                }}
              >
                <video
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    transform: `translate(-50%, -50%)`,
                    display: peer !== localVideo && !peer.enable && 'none',
                  }}
                  ref={
                    peer === localVideo
                      ? localVideo
                      : (instance) => {
                          if (peer.enable) {
                            provideMediaRef(peer.producerId, instance);
                          }
                        }
                  }
                  muted={peer === localVideo}
                  autoPlay
                  playsInline
                  onDoubleClick={() => {
                    if (peer !== localVideo) {
                      setFullScreenVideo((prev) => (prev ? null : peer.producerId));
                    }
                  }}
                />
                {((peer !== localVideo && !peer.enable && peer.kind === 'video') ||
                  (peer === localVideo && !cameraOn && !screenShare)) && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img style={{ maxWidth: '30%' }} alt="камера выключена" width="40%" src={noCamera} />
                  </div>
                )}
                <div className={styles.userName}>{peer.label ? peer.label : 'Вы'}</div>
                {peer.kind === 'video' &&
                  usersToShow.find((user) => peer.id === user.id && user.kind === 'audio')?.enable === false && (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        color: '#ff7066',
                        fontSize: '15px',
                        fontWeight: 'bold',
                        backgroundColor: 'white',
                        width: '25px',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                      }}
                    >
                      <AudioMutedOutlined />
                    </div>
                  )}
                {peer !== localVideo && (
                  <Dropdown
                    placement="bottomRight"
                    menu={{
                      items: [
                        {
                          key: !mutedUsers.includes(peer.id) ? 'mute' : 'unmute',
                          label: !mutedUsers.includes(peer.id) ? 'Заглушить' : 'Включить звук',
                        },
                      ],
                      onClick: (e) => handleVideoButtonClick(e, peer.id),
                    }}
                    trigger={['click']}
                  >
                    <div className={styles.videoButton}>
                      <MoreOutlined style={{ fontSize: '21px' }} />
                    </div>
                  </Dropdown>
                )}
              </div>
            );
          })}
      </div>
      <div className={styles.fixedButtons}>
        <div
          className={`${styles.button} ${styles.mobileOnly}`}
          style={{ display: isMobile ? 'flex' : 'none' }}
          onClick={toggleFacingMode}
        >
          <RetweetOutlined />
        </div>
        <Tooltip title={cameraOn ? 'Выключить камеру' : 'Включить камеру'}>
          <div
            style={{ backgroundColor: cameraOn ? 'green' : 'gray' }}
            className={styles.button}
            onClick={() => handleCameraClick()}
          >
            <VideoCameraOutlined />
          </div>
        </Tooltip>
        <Tooltip title={audioOn ? 'Выключить микрофон' : 'Включить микрофон'}>
          <div
            style={{ backgroundColor: audioOn ? 'green' : 'gray' }}
            className={styles.button}
            onClick={() => handleAudioClick()}
          >
            <AudioOutlined />
          </div>
        </Tooltip>
        <Tooltip title="Отключиться от звонка">
          <div className={`${styles.button} ${styles.endCall}`} onClick={() => handleStopCallClick()}>
            <PhoneOutlined style={{ fontSize: '30px' }} rotate={-135} />
          </div>
        </Tooltip>
        <Tooltip title={screenShare ? 'Выключить демонстрацию экрана' : 'Включить демонстрацию экрана'}>
          <div
            style={{ backgroundColor: screenShare ? 'green' : 'gray' }}
            className={styles.button}
            onClick={() => handleScreenClick()}
          >
            <DesktopOutlined />
          </div>
        </Tooltip>
        <Dropdown
          placement="topLeft"
          menu={{
            items: settingsItems,
            onClick: (e) => handleSettingsClick(e),
          }}
          trigger={['click']}
        >
          <div className={`${styles.button} ${styles.settings}`}>
            <SettingOutlined />
          </div>
        </Dropdown>
      </div>
    </div>
  );
}
