import { Button, Drawer, List, Tooltip, message } from 'antd';
import { UsergroupDeleteOutlined, CopyOutlined } from '@ant-design/icons';
import styles from './GroupCallsPage.module.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import InviteToCallModal from './InviteToCallModal';
import { useParams } from 'react-router';

export const UsersListDrawer = ({ users }) => {
  const { id: roomName, fullRoomName } = useParams();
  const [isUsersDrawerOpen, setIsUsersDrawerOpen] = useState(false);
  const userInfo = useSelector((state) => state.userInfo.userState);
  const handleCancel = () => {
    setIsUsersDrawerOpen(false);
  };

  return (
    <>
      <Tooltip placement="bottom" title="Участники">
        <div
          className={`${styles.button} ${styles.users}`}
          style={{
            position: 'fixed',
            top: '80px',
            right: '0',
            transform: 'translateX(-50%)',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            zIndex: '1000',
          }}
          onClick={() => setIsUsersDrawerOpen(true)}
        >
          <UsergroupDeleteOutlined />
        </div>
      </Tooltip>
      <Drawer title={'Список участников'} open={isUsersDrawerOpen} onClose={handleCancel} cancelText="Отмена">
        <List
          dataSource={users.filter(
            (peer, index, array) =>
              array
                .map((user) => {
                  if (user.kind !== 'audio') {
                    return user.label;
                  }
                })
                .indexOf(peer.label) === index
          )}
          renderItem={(user, index) => {
            return (
              <List.Item className={`${styles.userItem} ${index === 0 ? styles.currentUser : ''}`}>
                <div className={styles.userInfo}>
                  <p>{index === 0 ? 'Вы' : user.label}</p>
                </div>
              </List.Item>
            );
          }}
        />
        <InviteToCallModal userInfo={userInfo} callParticipants={users.map((user) => user.label)} />
        <Button
          icon={<CopyOutlined />}
          type="default"
          onClick={() => {
            navigator.clipboard.writeText(`${roomName}/${fullRoomName}`);
            message.success('Приглашение скопировано');
          }}
          style={{ margin: '7px auto', width: '100%' }}
        >
          Копировать приглашение
        </Button>
      </Drawer>
    </>
  );
};
