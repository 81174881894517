import React, { useState, useEffect } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { Button, Table, Form, notification, Modal, Input, Select, Space } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import getFiltersInColumn from '../../utils/filteredColumns';
import { isMobile } from 'react-device-detect';
import FeatureModal from '../OptionsFeatureModal/FeatureModal';

const UsersRequestTable = () => {

  const { request } = useHttp();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState();

  const columns = isMobile ?
    [
      {
        title: 'Информация',
        dataIndex: 'user_info',
        render: (text, record) => {
          return (
            <Space direction='vertical'>
              {record.name}
              {record.sub_id_name}
            </Space>
          )
        }
      },
      {
        title: 'Действия',
        render: (text, record) => {
          return (
            <Space direction='vertical'>
              <LocalizedModalOk name={record.name} />
              <FeatureModal name={record.name}
                email={record.email}
                tel={record.tel}
                sub_id_name={record.sub_id_name} />
              <LocalizedModalCancel name={record.name} />
            </Space>
          )
        }
      }
    ]
    :
    [
      {
        title: 'Позывной',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
          getFiltersInColumn(
            data,
            setSelectedKeys,
            selectedKeys,
            confirm,
            'name'
          ),
        onFilter: (value, record) => {
          if (value === 'нет данных' && !record.name) {
            return !record.name;
          } else {
            return record.name?.includes(value);
          }
        },
        sorter: (a, b) => a.name?.localeCompare(b.name),
        render: (text, record) => { return record.name }
      },
      {
        title: 'Логин',
        dataIndex: 'email',
        key: 'email',
        width: 150,
        filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
          getFiltersInColumn(
            data,
            setSelectedKeys,
            selectedKeys,
            confirm,
            'email'
          ),
        onFilter: (value, record) => {
          if (value === 'нет данных' && !record.email) {
            return !record.email;
          } else {
            return record.email?.includes(value);
          }
        },
        sorter: (a, b) => a.email?.localeCompare(b.email),
        render: (text, record) => { return record.email }
      },
      {
        title: 'Телефон/Telegram',
        dataIndex: 'tel',
        key: 'tel',
        width: 150,
        filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
          getFiltersInColumn(
            data,
            setSelectedKeys,
            selectedKeys,
            confirm,
            'tel'
          ),
        onFilter: (value, record) => {
          if (value === 'нет данных' && !record.tel) {
            return !record.tel;
          } else {
            return record.tel?.includes(value);
          }
        },
        sorter: (a, b) => a.tel?.localeCompare(b.tel),
        render: (text, record) => { return record.tel }
      },
      {
        title: 'Ответственный',
        dataIndex: 'sub_id_name',
        key: 'sub_id_name',
        width: 150,
        render: (text, record) => record.sub_id_name
      },
      {
        title: 'Действия',
        dataIndex: 'action',
        key: 'action',
        width: 50,
        fixed: 'right',
        render: (text, record) => {
          return (
            <div style={{textAlign: 'center'}}>
              <Space>
                <LocalizedModalOk name={record.name} />
                <LocalizedModalCancel name={record.name} />
              </Space>
            </div>
          )
        },
      }
    ];

  const LocalizedModalOk = (params) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      toApprove(params.name);
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
      <>
        <Button type="primary" size={isMobile ? 'small' : ''}  style={{width: '100%', position: 'relative'}} onClick={showModal}>
          {isMobile ? 'Подтвердить' : <CheckOutlined />}
        </Button>
        <Modal title="Подтвердите действие" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} cancelText="Отмена">
          <p>Вы уверены что хотите подтвердить эту заявку?</p>
        </Modal>
      </>
    );
  };

  const LocalizedModalCancel = (params) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      toCancelled(params.name);
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
      <>
        <Button type="primary" size={isMobile ? 'small' : ''} style={{width: '100%', position: 'relative'}} onClick={showModal} danger>
          {isMobile ? 'Отменить' : <CloseOutlined />}
        </Button>
        <Modal title="Подтвердите действие" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}  cancelText="Отмена">
          <p>Вы уверены что хотите отклонить эту заявку?</p>
        </Modal>
      </>
    );
  };

  const toApprove = async (name) => {
    try {
      await request('/api/new_user_request/approved', 'POST', {
        name: name
      });
      fetchListUsersTable();
    } catch (err) {
      console.log('ERR: ', err);
    }
  }

  const toCancelled = async (name) => {
    try {
      await request('/api/new_user_request/cancelled', 'POST', {
        name: name
      });
      fetchListUsersTable();
    } catch (err) {
      console.log('ERR: ', err);
    }
  }

  //* GET ALL USERS
  const fetchListUsersTable = async () => {
    try {
      setLoading(true);
      request(`api/new_user_request/user_registered`)
        .then((resData) => {
          setData(resData.map((el, i) => ({ ...el, key: i + 1 })));
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.log('error-fetchDataChartPage >>>', error);
    }
  };

  useEffect(() => {
    fetchListUsersTable();
  }, []);

  const onFinish = async (values) => {
    try {
      request('/api/users/getList');
      await fetchListUsersTable();
      form.resetFields();
    } catch (error) {
      console.log('error-getDeleteCell >>>', error, error.message);
    }
  };

  return (
    <>
      <div className='regreq_pos'>
        <Form form={form} onFinish={onFinish}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={isMobile ? {} : {
              x: 2500
            }}
            style={{ marginTop: '4%' }}
            bordered
          />
        </Form>
      </div>
    </>
  );

}

export default UsersRequestTable;
