import { v4 } from 'uuid';
import { Modal, Form, Input, Button, message, Tooltip, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useCallback, useRef } from 'react';
import noCamera from '../../images/no-camera.png';
import { AudioOutlined, VideoCameraOutlined, AudioMutedOutlined } from '@ant-design/icons';

export const NameModal = ({ text }) => {
  const history = useNavigate();
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [canUseAudio, setCanUseAudio] = useState(true);
  const [canUseVideo, setCanUseVideo] = useState(true);
  const [cameraOn, setCameraOn] = useState(true);
  const [audioOn, setAudioOn] = useState(true);
  const [selectedBitrate, setSelectedBitrate] = useState('max');

  const localMediaStream = useRef(null);
  const localVideo = useRef(null);

  useEffect(() => {
    if (isNameModalOpen) {
      getLocalStream();
    }
  }, [isNameModalOpen]);

  const getLocalStream = useCallback(async () => {
    let letUseVideo = true,
      letUseAudio = true;
    try {
      localMediaStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { width: { min: 640, max: 1920 }, height: { min: 400, max: 1080 } },
      });
      await streamSuccess(localMediaStream.current);
    } catch (error) {
      try {
        localMediaStream.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        await streamSuccess(localMediaStream.current);
        setCameraOn(false);
        setAudioOn(true);
      } catch (audioError) {
        setAudioOn(false);
        letUseAudio = false;
        setCanUseAudio(false);
        console.error('Ошибка доступа к аудио:', audioError.message);
      }

      try {
        localMediaStream.current = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true,
        });
        await streamSuccess(localMediaStream.current);
        setCameraOn(true);
        setAudioOn(false);
      } catch (videoError) {
        setCameraOn(false);
        letUseVideo = false;
        setCanUseVideo(false);
        console.error('Ошибка доступа к аудио:', videoError.message);
      }

      if (!letUseAudio && !letUseVideo) {
        await streamSuccess();
      }
    }
  }, []);

  const streamSuccess = async (stream) => {
    if (stream) {
      if (localVideo.current) {
        localVideo.current.srcObject = stream;
      }
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onFinish(values);
      })
      .catch((info) => {
        console.log('Validate failed: ', info);
      });
  };

  const handleCancel = () => {
    if (localMediaStream.current) {
      localMediaStream.current.getTracks().forEach((track) => track.stop());
    }
    setIsNameModalOpen(false);
  };

  const onFinish = async (values) => {
    if (localMediaStream.current) {
      localMediaStream.current.getTracks().forEach((track) => track.stop());
    }
    const roomName = values.roomName;
    const v4gen = v4();
    history(`/room/${v4gen}/${roomName}/${audioOn}/${cameraOn}/${selectedBitrate}`);
  };

  const handleCameraClick = async () => {
    if (canUseVideo) {
      setCameraOn(!cameraOn);
    } else {
      message.error('Нет доступа к камере');
    }
  };

  const handleAudioClick = () => {
    if (canUseAudio) {
      setAudioOn(!audioOn);
    } else {
      message.error('Нет доступа к микрофону');
    }
  };

  const handleChangeBitrate = (bitrate) => {
    setSelectedBitrate(bitrate);
  };

  return (
    <>
      <Button
        type="primary"
        style={{ margin: '20px 0 20px 20px' }}
        onClick={() => {
          setIsNameModalOpen(true);
        }}
      >
        Создать новый звонок
      </Button>
      <Modal title={text} open={isNameModalOpen} onOk={handleOk} onCancel={handleCancel} cancelText="Отмена">
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          form={form}
          autoComplete="off"
        >
          <Form.Item
            label="Название "
            name="roomName"
            rules={[
              {
                required: true,
                message: 'Необходимо указать название группы!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div
            style={{
              position: 'relative',
              width: '100%',
              paddingTop: '56.25%',
              borderRadius: '15px',
              overflow: 'hidden',
              backgroundColor: 'black',
              justifyContent: 'center',
              alignItems: 'center',
              border: '3px solid rgb(152, 202, 255)',
            }}
          >
            <video
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '100%',
                height: '100%',
                maxWidth: '100%',
                maxHeight: '100%',
                transform: `translate(-50%, -50%)`,
                display: !cameraOn && 'none',
              }}
              ref={localVideo}
              muted
              autoPlay
              playsInline
            />
            {!cameraOn && (
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img style={{ maxWidth: '30%' }} alt="камера выключена" width="40%" src={noCamera} />
              </div>
            )}
            <div
              style={{
                position: 'absolute',
                bottom: '10px',
                left: '10px',
                color: 'white',
                fontSize: '13px',
                fontWeight: 'bold',
                textShadow: '0 1px 4px rgba(0, 0, 0, 0.5)',
              }}
            >
              Вы
            </div>
            {!audioOn && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  color: '#ff7066',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  backgroundColor: 'white',
                  width: '25px',
                  height: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                }}
              >
                <AudioMutedOutlined />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
            {'Битрейт: '}
            <Select
              defaultValue="Высокий (900 kbps)"
              onChange={handleChangeBitrate}
              style={{ marginLeft: '5px' }}
              options={[
                {
                  value: 0,
                  label: 'Низкий (100 kbps)',
                },
                {
                  value: 1,
                  label: 'Средний (300 kbps)',
                },
                {
                  value: 2,
                  label: 'Высокий (900 kbps)',
                },
              ]}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px' }}>
            <Tooltip title={cameraOn ? 'Выключить камеру' : 'Включить камеру'}>
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '19px',
                  cursor: 'pointer',
                  filter: 'drop-shadow(0px 8px 5px #00000057)',

                  backgroundColor: cameraOn ? 'green' : 'gray',
                }}
                onClick={() => handleCameraClick()}
              >
                <VideoCameraOutlined />
              </div>
            </Tooltip>
            <Tooltip title={audioOn ? 'Выключить микрофон' : 'Включить микрофон'}>
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '20px',
                  cursor: 'pointer',
                  filter: 'drop-shadow(0px 8px 5px #00000057)',
                  marginLeft: '7px',
                  backgroundColor: audioOn ? 'green' : 'gray',
                }}
                onClick={() => handleAudioClick()}
              >
                <AudioOutlined />
              </div>
            </Tooltip>
          </div>
        </Form>
      </Modal>
    </>
  );
};
