import { Modal, Form, message, Tooltip, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useCallback, useRef } from 'react';
import noCamera from '../../images/no-camera.png';
import { AudioOutlined, VideoCameraOutlined, AudioMutedOutlined } from '@ant-design/icons';

export const EnterModal = ({ id, roomName, isEnterModalOpen, setIsEnterModalOpen }) => {
  const history = useNavigate();
  const [form] = Form.useForm();
  const [canUseAudio, setCanUseAudio] = useState(true);
  const [canUseVideo, setCanUseVideo] = useState(true);
  const [cameraOn, setCameraOn] = useState(true);
  const [audioOn, setAudioOn] = useState(true);
  const [selectedBitrate, setSelectedBitrate] = useState('max');

  const localMediaStream = useRef(null);
  const localVideo = useRef(null);

  useEffect(() => {
    if (isEnterModalOpen) {
      getLocalStream();
    }
    return () => {
      stopLocalStream();
    };
  }, [isEnterModalOpen]);

  const getLocalStream = useCallback(async () => {
    try {
      localMediaStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { width: { min: 640, max: 1920 }, height: { min: 400, max: 1080 } },
      });
      streamSuccess(localMediaStream.current);
    } catch (error) {
      handleStreamError(error);
    }
  }, []);

  const handleStreamError = async (error) => {
    console.error('Ошибка доступа к аудио/видео:', error.message);

    let audioAvailable = true;
    let videoAvailable = true;

    try {
      localMediaStream.current = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      streamSuccess(localMediaStream.current);
      setCameraOn(false);
    } catch (audioError) {
      setCanUseAudio(false);
      setAudioOn(false);
      audioAvailable = false;
    }

    try {
      localMediaStream.current = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
      streamSuccess(localMediaStream.current);
      setAudioOn(false);
    } catch (videoError) {
      setCanUseVideo(false);
      setCameraOn(false);
      videoAvailable = false;
    }

    if (!audioAvailable && !videoAvailable) {
      streamSuccess(null);
    }
  };

  const streamSuccess = (stream) => {
    if (localVideo.current) {
      localVideo.current.srcObject = stream || null;
    }
  };

  const stopLocalStream = () => {
    if (localMediaStream.current) {
      localMediaStream.current.getTracks().forEach((track) => track.stop());
      localMediaStream.current = null;
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onFinish(values);
      })
      .catch((info) => {
        console.log('Validate failed:', info);
      });
  };

  const handleCancel = () => {
    stopLocalStream();
    setIsEnterModalOpen(false);
  };

  const onFinish = () => {
    stopLocalStream();
    setIsEnterModalOpen(false);
    history(`/room/${id}/${roomName}/${audioOn}/${cameraOn}/${selectedBitrate}`);
  };

  const handleCameraClick = () => {
    if (canUseVideo) {
      setCameraOn(!cameraOn);
    } else {
      message.error('Нет доступа к камере');
    }
  };

  const handleAudioClick = () => {
    if (canUseAudio) {
      setAudioOn(!audioOn);
    } else {
      message.error('Нет доступа к микрофону');
    }
  };

  const handleChangeBitrate = (bitrate) => {
    setSelectedBitrate(bitrate);
  };

  return (
    <>
      <Modal
        title={`Подключиться к комнате ${roomName}?`}
        open={isEnterModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
      >
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          form={form}
          autoComplete="off"
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
              paddingTop: '56.25%',
              borderRadius: '15px',
              overflow: 'hidden',
              backgroundColor: 'black',
              justifyContent: 'center',
              alignItems: 'center',
              border: '3px solid rgb(152, 202, 255)',
            }}
          >
            <video
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '100%',
                height: '100%',
                maxWidth: '100%',
                maxHeight: '100%',
                transform: `translate(-50%, -50%)`,
                display: !cameraOn && 'none',
              }}
              ref={localVideo}
              muted
              autoPlay
              playsInline
            />
            {!cameraOn && (
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img style={{ maxWidth: '30%' }} alt="камера выключена" width="40%" src={noCamera} />
              </div>
            )}
            <div
              style={{
                position: 'absolute',
                bottom: '10px',
                left: '10px',
                color: 'white',
                fontSize: '13px',
                fontWeight: 'bold',
                textShadow: '0 1px 4px rgba(0, 0, 0, 0.5)',
              }}
            >
              Вы
            </div>
            {!audioOn && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  color: '#ff7066',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  backgroundColor: 'white',
                  width: '25px',
                  height: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                }}
              >
                <AudioMutedOutlined />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
            {'Битрейт: '}
            <Select
              defaultValue="Высокий (900 kbps)"
              onChange={handleChangeBitrate}
              style={{ marginLeft: '5px' }}
              options={[
                {
                  value: 0,
                  label: 'Низкий (100 kbps)',
                },
                {
                  value: 1,
                  label: 'Средний (300 kbps)',
                },
                {
                  value: 2,
                  label: 'Высокий (900 kbps)',
                },
              ]}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px' }}>
            <Tooltip title={cameraOn ? 'Выключить камеру' : 'Включить камеру'}>
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '19px',
                  cursor: 'pointer',
                  filter: 'drop-shadow(0px 8px 5px #00000057)',

                  backgroundColor: cameraOn ? 'green' : 'gray',
                }}
                onClick={() => handleCameraClick()}
              >
                <VideoCameraOutlined />
              </div>
            </Tooltip>
            <Tooltip title={audioOn ? 'Выключить микрофон' : 'Включить микрофон'}>
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '20px',
                  cursor: 'pointer',
                  filter: 'drop-shadow(0px 8px 5px #00000057)',
                  marginLeft: '7px',
                  backgroundColor: audioOn ? 'green' : 'gray',
                }}
                onClick={() => handleAudioClick()}
              >
                <AudioOutlined />
              </div>
            </Tooltip>
          </div>
        </Form>
      </Modal>
    </>
  );
};
